@import '../../theme';

.wrapper{

    .icon {
        height: 1em;
        width: 1em;
    }

    &.verticalCenter{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
