@import '../../theme';

.overlay {
    z-index: 1101;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color-dark-sky, 0.5);
    overflow-x: hidden;
    overflow-y: auto;

    &.center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.modal {
    font-family: inherit;
    max-width: 1000px;
    background-color: $color-snow;
    margin: 2rem auto;

    &.overflow{
        overflow: hidden;
    }

    &.small {
        width: 560px;
    }

    &.big{
        position: absolute;
        top: 2rem;
        bottom: 2rem;
        left: 2rem;
        right: 2rem;
        margin: 0;
        max-width: none;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .content {
            flex-grow: 1;

            iframe {
                height: 100%;
            }
        }
    }

    &.dialog {
        position: absolute;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        padding: 5rem;
        width: 400px;
        padding-bottom: 0;

        .header {
            background-color: transparent;
            box-shadow: none;
            justify-content: center;
            padding: 0 4rem 0rem;
            text-align: center;

            h2 {
                margin-bottom: 2rem;
            }
        }

        .content {
            padding: 0;

            span {
                margin-bottom: 4rem;
            }

            button {
                &:not(:last-of-type) {
                    margin-bottom: 2rem;
                }

                justify-content: center;
            }
        }
    }

    &.nofooter .content {
        padding-bottom: 4rem;
    }
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4rem 4rem 0 4rem;

    &.hasSubtitle h2{
        margin-bottom: 2rem;
    }

    button {
        @include unstyled_button(4rem);
        padding: 0;
        margin: 0;
        border-radius: .5rem;

        &:hover{
            color: $color-warm-sand;
            background-color: $color-grey;

            svg {
                fill: $color-warm-sand;
            }
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 0 4rem;

    &.noPadding {
        padding: 0;
    }
}

.footer {
    text-align: right;
    border-top: 1px solid $color-warm-grey;
    padding: 4rem;

}

[class~="ReactModal__Overlay"] {
    opacity: 0;
    transition: opacity 100ms linear;
}

[class~="ReactModal__Overlay--after-open"] {
    opacity: 1;
}

[class~="ReactModal__Overlay--before-close"]{
    opacity: 0;
}

.loadingScreen{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
